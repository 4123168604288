import { type DefaultTheme } from 'styled-components';

export const defaultTheme: DefaultTheme = {
  colors: {
    blue: '#0c497a',
    green: '#3fab49',
    gray: '#a8a8a8',
    text: '#3d3d3d',
    background: '#ffffff',
    error: '#cc0000',
    disabled: '#f4f4f4',
    lightGray: '#ededed',
    badge: '#cc0000',
  },
  screens: {
    large: 1512,
    medium: 1200,
    small: 800,
    mobile: 480
  },
  maxWidth: 1050,
  navSize: {
    small: 60,
    other: 60
  }
};
import { usePostSync } from '@bbcs/bbcs-fetch';
import useConfig from 'hooks/useConfig';
import useQueryParams from 'hooks/useQueryParams';

export default () => {
  const { enabled, site, region } = useConfig();
  const { cmp } = useQueryParams();

  const log = {
    time: (new Date()).getTime(),
    page: window.location.pathname,
    screenWidth: window.screen.width,
    screenHeight: window.screen.height,
    cmp,
    site,
    region
  };

  if (enabled === false) return;

  try { usePostSync('/log/page', log); } catch { }
};

const storageKey = 'useDyslexiaFont';

export const saveUsingDyslexiaFont = (useSpecialFont : Boolean) => {
  if (useSpecialFont) localStorage.setItem(storageKey, 'true');
  else localStorage.removeItem(storageKey);
  setDyslexiaFont();
};

export const setDyslexiaFont = () => {
  const useFont = localStorage.getItem(storageKey) === 'true';
  if (useFont) document.body.classList.add('dyslexia');
  else document.body.classList.remove('dyslexia');
};
/* eslint-disable no-console */
import FullScreenSpinnerLayout from 'layouts/FullScreenSpinnerLayout';
import { ReactNode } from 'react';
import ConfigContext from './ConfigContext';
import { useFetchConfig } from './useFetchConfig';

export default (props : { children : ReactNode }) => {
  const { children } = props;
  const context = useFetchConfig();

  if (!context) return <FullScreenSpinnerLayout />;
  return (
    <ConfigContext.Provider value={context}>{children}</ConfigContext.Provider>
  );
};

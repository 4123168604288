import styled from 'styled-components';

export default styled.div`
  margin-top: calc(env(safe-area-inset-top) + var(--visual-offset-top));
  height: calc( var(--visual-height) - env(safe-area-inset-top) - env(safe-area-inset-bottom) );
  width: calc(100vw - env(safe-area-inset-left) - env(safe-area-inset-right));
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: absolute;
  overscroll-behavior: none;
`;
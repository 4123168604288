import React from 'react';
import Config from 'types/Config';

interface Context {
    config: Config,
    setRegion: (regionId?: string) => void
}

const ConfigContext = React.createContext<Context>(undefined as unknown as Context);

export default ConfigContext;
